<template>
    <div class="setting-index-view">
        <h2>管理员你好，这里是平台管理控制台</h2>
        <div class="content">
            <h3>缓存</h3>
            <n-input v-model:value="dbIndex" style="width: 100px"></n-input>
            <n-button
                class="item"
                type="primary"
                @click="clearRedisDB1Cache"
                :loading="clearRedisDB1CacheLoading"
                >清空Redis缓存</n-button
            >
            <h3>开发者</h3>
            <n-button class="item" type="primary" @click="showTokenModal = true"
                >token管理</n-button
            >
        </div>
        <n-modal v-model:show="showTokenModal">
            <n-card style="width: 600px" title="Token管理" preset="dialog">
                <n-tabs v-model:value="tokenModalTab">
                    <n-tab-pane name="create">
                        <n-form>
                            <n-form-item label="Identity">
                                <n-input
                                    v-model:value="tokenIdentity"
                                ></n-input>
                            </n-form-item>
                            <n-form-item label="过期时间（分钟）">
                                <n-input v-model:value="expireTime"></n-input>
                            </n-form-item>
                        </n-form>
                        <n-input-group>
                            <n-input
                                ref="tokenResRef"
                                :value="tokenRes"
                                readonly
                                placeholder="Token生成结果"
                            >
                            </n-input>
                            <n-button type="primary" @click="selectTokenRes"
                                >复制</n-button
                            >
                        </n-input-group>
                    </n-tab-pane>
                    <n-tab-pane name="block">
                        <n-form>
                            <n-form-item label="JTI">
                                <n-input v-model:value="tokenJTI"></n-input>
                            </n-form-item>
                            <n-form-item label="过期时间（分钟）">
                                <n-input v-model:value="expireTime"></n-input>
                            </n-form-item>
                        </n-form>
                    </n-tab-pane>
                </n-tabs>

                <template #action>
                    <div style="display: flex; justify-content: right">
                        <n-button
                            type="primary"
                            @click="tokenSubmit"
                            :loading="tokenLoading"
                            >{{
                                tokenModalTab === 'create' ? '生成' : '屏蔽'
                            }}</n-button
                        >
                    </div>
                </template>
            </n-card>
        </n-modal>
    </div>
</template>

<style lang="less">
@import '../../../common/common.less';

.setting-index-view {
    .common-content;

    .content {
        position: relative;
        min-height: 500px;
        .item {
            margin: 0 10px;
        }
    }
}
</style>

<script setup>
import { ref } from 'vue';
import {
    NButton,
    NModal,
    NCard,
    NForm,
    NFormItem,
    NInput,
    NInputGroup,
    NTabs,
    NTabPane,
    useMessage,
} from 'naive-ui';

import { SettingsAPI } from '@/common/API';

const message = useMessage();

let clearRedisDB1CacheLoading = ref(false);

let dbIndex = ref('1');

function clearRedisDB1Cache() {
    clearRedisDB1CacheLoading.value = true;
    SettingsAPI.clearRedisDB1Cache(dbIndex.value)
        .then(res => {
            clearRedisDB1CacheLoading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致操作失败');
                return;
            }
            message.success('操作完成');
        })
        .catch(err => {
            console.log(err);
            clearRedisDB1CacheLoading.value = false;
            message.error('未知异常导致操作失败');
        });
}

let showTokenModal = ref(false);
let tokenModalTab = ref('create');
let tokenLoading = ref(false);

let tokenIdentity = ref('');
let tokenJTI = ref('');
let expireTime = ref('');
let tokenRes = ref('');
let tokenResRef = ref();

function tokenSubmit() {
    tokenLoading.value = true;

    let promise =
        tokenModalTab.value === 'create'
            ? SettingsAPI.createToken(tokenIdentity.value, expireTime.value)
            : SettingsAPI.blockToken(tokenJTI.value, expireTime.value);

    promise
        .then(res => {
            tokenLoading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致操作失败');
                return;
            }
            if (tokenModalTab.value !== 'create') {
                message.success('操作完成');
                showTokenModal.value = false;
                return;
            }
            tokenRes.value = res.data.access_token;
        })
        .catch(err => {
            tokenLoading.value = false;
            console.log(err);
            message.error('未知异常导致操作失败');
        });
}

function selectTokenRes() {
    if (!navigator.clipboard) {
        message.error(
            '当前环境不支持，原因可能是：非安全上下文（https）或浏览器版本过低'
        );
        return;
    }
    navigator.clipboard.writeText(tokenRes.value).then(_ => {
        message.success('Token已写入到剪贴板中');
    });
}
</script>
